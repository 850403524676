import { UserAuthDTO } from '../auth/userAuth.dto';
import { UserModel } from '../models/auth/user.model';
import { StringUtil } from '../utils/string.util';

class TokenService {
  JWTTOKEN = 'jwttoken';
  JWTREFRESHTOKEN = 'jwtrefreshtoken';
  USERNAME = 'username';
  USERAUTHOBJECT = 'userauthobject';
  PERMISSIONS = 'permissions';
  CURRENTUSER = 'currentuser';
  USERIMAGE = 'userimage';

  isLoggedIn(): boolean {
    return StringUtil.notUndefinedOrBlank(this.getCurrentUser()?.userId);
  }

  isAnonymous(): boolean {
    return !this.isLoggedIn();
  }

  setUserImage(imageUrl: string): string {
    if (StringUtil.notUndefinedOrBlank(imageUrl)) {
      // localStorage.setItem(this.USERIMAGE, imageUrl);
      // return imageUrl;
    }

    return 'https://cliffhangertv.com/img/profile/profile-7.webp';
  }

  getUserImage(): string {
    // const userImage = localStorage.getItem(this.USERIMAGE) ?? '';

    return 'https://cliffhangertv.com/img/profile/profile-7.webp';
  }

  getJWTAccessToken() {
    const jwtToken = localStorage.getItem(this.JWTTOKEN) ?? '';

    return jwtToken;
  }

  setJWTAccessToken(token: string) {
    localStorage.setItem(this.JWTTOKEN, `Bearer ${token}`);
  }

  getRefreshToken() {
    const refreshToken = localStorage.getItem(this.JWTREFRESHTOKEN) ?? '';
    return refreshToken;
  }

  setRefreshToken(refreshToken: string) {
    localStorage.setItem(this.JWTREFRESHTOKEN, `Bearer ${refreshToken}`);
  }

  getUsername() {
    const username = localStorage.getItem(this.USERNAME) ?? '';
    return username;
  }

  setUsername(username: string) {
    localStorage.setItem(this.USERNAME, username);
  }

  getUserAuth(): UserAuthDTO {
    const userAuth: UserAuthDTO = JSON.parse(localStorage.getItem(this.USERAUTHOBJECT) ?? '{}');

    return userAuth;
  }

  setUserAuth(userAuth: UserAuthDTO) {
    localStorage.setItem(this.USERAUTHOBJECT, JSON.stringify(userAuth));
  }

  getCurrentUser(): UserModel {
    const currentUser: UserModel = JSON.parse(localStorage.getItem(this.CURRENTUSER) ?? '{}');

    return currentUser;
  }

  setCurrentUser(currentUser: UserModel) {
    localStorage.setItem(this.CURRENTUSER, JSON.stringify(currentUser));
  }

  getPermissions(): string[] {
    const permissions: string[] = JSON.parse(localStorage.getItem(this.PERMISSIONS) ?? '[]') ?? [];

    return permissions;
  }

  setPermissions(permissions: string[]) {
    localStorage.setItem(this.PERMISSIONS, JSON.stringify(permissions));
  }

  clearTokens() {
    localStorage.clear();
  }
}

export default new TokenService();
