import { RouteObject } from 'react-router-dom';
import { DEFAULT_PATHS } from '../config';
import { RouteModel } from '../models/route/route.model';
import routesAndMenuItems from '../routes';
import { StringUtil } from '../utils/string.util';

export class HelperRoutingV6 {
  static getDefaultRoutes(): RouteModel[] {
    return routesAndMenuItems.defaultRoutes;
  }

  static getSidebarItems(): RouteModel[] {
    return routesAndMenuItems.sidebarItems;
  }

  static getMainMenuItems(): RouteModel[] {
    const mainMenuItems: RouteModel[] = routesAndMenuItems.mainMenuItems;

    return mainMenuItems;
    // Update the name of the active planning period
    // mainMenuItem.label = activePlanningPeriodName + ' (active)';
    // return routesAndMenuItems.mainMenuItems;
  }

  static filterRoutes(routes: RouteModel[], permissions: string[]): RouteModel[] {
    const filteredRoutes: RouteModel[] = (routes ?? []).filter(
      (route) =>
        StringUtil.isUndefinedOrBlank(route.permission) ||
        permissions.findIndex((permission) => (permission ?? '').toLowerCase() === (route.permission ?? '').toLowerCase()) !== -1 ||
        route?.permission?.toLowerCase() === 'any',
    );

    return filteredRoutes.map((route) => {
      route.subs = (route.subs ?? []).filter(
        (subRoute) =>
          StringUtil.isUndefinedOrBlank(subRoute.permission) ||
          permissions.findIndex((permission) => (permission ?? '').toLowerCase() === (subRoute.permission ?? '').toLowerCase()) !== -1 ||
          subRoute?.permission?.toLowerCase() === 'any',
      );

      return route;
    });
  }

  static getRoutes(): RouteObject[] {
    const routes: RouteObject[] = [];

    // Default Routes
    routes.push(
      ...HelperRoutingV6.getDefaultRoutes().map((route): RouteObject => {
        return { path: route.routePath, lazy: route.lazy, loader: route.loader };
      }),
    );

    // side Menu Routes
    const appRoute: RouteObject = {};
    appRoute.path = DEFAULT_PATHS.APP;
    appRoute.lazy = async () => {
      const { Component } = await import('../App');
      return { Component };
    };

    HelperRoutingV6.getSidebarItems().forEach((sidebarItem) => {
      const mainRoutes: RouteObject = {};
      mainRoutes.path = sidebarItem.routePath;

      if (sidebarItem.lazy) {
        mainRoutes.lazy = sidebarItem.lazy;
      }

      if (sidebarItem.loader) {
        mainRoutes.loader = sidebarItem.loader;
      }

      if (sidebarItem.subs && sidebarItem.subs.length > 0) {
        sidebarItem.subs.forEach((sub) => {
          const subRoute: RouteObject = {};
          subRoute.path = sub.routePath;

          if (sub.lazy) {
            subRoute.lazy = sub.lazy;
          }

          if (sub.loader) {
            subRoute.loader = sub.loader;
          }

          // appRoute.children ? appRoute.children.push(subRoute) : (appRoute.children = [subRoute]);
          mainRoutes.children ? mainRoutes.children.push(subRoute) : (mainRoutes.children = [subRoute]);

          if (sub.subs && sub.subs.length > 0) {
            sub.subs.forEach((sub) => {
              const subSubRoute: RouteObject = {};
              subSubRoute.path = sub.routePath;

              if (sub.lazy) {
                subSubRoute.lazy = sub.lazy;
              }

              if (sub.loader) {
                subSubRoute.loader = sub.loader;
              }

              // appRoute.children ? appRoute.children.push(subSubRoute) : (appRoute.children = [subSubRoute]);
              subRoute.children ? subRoute.children.push(subSubRoute) : (subRoute.children = [subSubRoute]);
            });
          }
        });
      }

      appRoute.children ? appRoute.children.push(mainRoutes) : (appRoute.children = [mainRoutes]);
    });

    HelperRoutingV6.getMainMenuItems().forEach((mainMenuItem) => {
      const mainRoutes: RouteObject = {};
      mainRoutes.path = mainMenuItem.routePath;

      if (mainMenuItem.lazy) {
        mainRoutes.lazy = mainMenuItem.lazy;
      }

      if (mainMenuItem.loader) {
        mainRoutes.loader = mainMenuItem.loader;
      }

      appRoute.children ? appRoute.children.push(mainRoutes) : (appRoute.children = [mainRoutes]);
    });

    routes.push(appRoute);

    for (const route of routes) {
      route.hydrateFallbackElement = <div>Loading...</div>;
    }

    return routes;
  }
}
