import { CredentialResponse } from '@react-oauth/google';
import { RESTAPIAccessV2, postURIAndReturnJSONAxiosNoAuth, refreshOAuthToken } from '../dataAccess/apiAccessV2.da';
import { default as TokenService, default as tokenService } from '../dataAccess/tokenService';
import { RegisterDTO } from '../models/auth/register.dto';
import { UserAccessDTO } from '../models/auth/userIdAccess.dto';
import { User } from '../models/authSvcModels/userAuth.model';
import { StringUtil } from '../utils/string.util';
import { UserAuthDTO } from './userAuth.dto';

export function useToken() {
  function decodeJwtResponse(token: string) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  async function registerUser(newUser: RegisterDTO): Promise<User> {
    try {
      const uri = process.env.NODE_ENV !== 'production' ? 'http://localhost:3001/auth/register/' : 'https://api.cliffhangertv.com/auth/register/';

      const data: User = await postURIAndReturnJSONAxiosNoAuth(uri, newUser);

      return data;
    } catch (error) {
      console.log(error);
      throw new Error('registration failed');
    }
  }

  async function googleLogIn(credentialResponse: CredentialResponse): Promise<UserAccessDTO> {
    try {
      const uri = process.env.NODE_ENV !== 'production' ? 'http://localhost:3001/auth/googleLogIn/' : 'https://api.cliffhangertv.com/auth/googleLogIn/';

      const body = {
        credential: credentialResponse.credential,
        clientId: credentialResponse.clientId,
        selectBy: credentialResponse.select_by,
      };

      const data = await postURIAndReturnJSONAxiosNoAuth(uri, body);

      const creds: { email: string; name: string; picture: string } = decodeJwtResponse(credentialResponse.credential ?? '');

      TokenService.setUsername(creds.email);
      TokenService.setJWTAccessToken(data.accessToken);
      TokenService.setRefreshToken(data.refreshToken);
      tokenService.setUserImage(creds.picture);

      const response = await RESTAPIAccessV2.get('/auth/useraccess');

      const userAccess: UserAccessDTO = response.data;

      if (Array.isArray(userAccess) && userAccess.length >= 1) {
        setToken(creds.email, userAccess[0]?.userId ?? '');
        setPermissions(userAccess[0].permissions ?? []);
      }

      return userAccess;
    } catch (error) {
      console.log(error);
      throw new Error('login failed');
    }
  }

  async function logIn(email: string, password: string): Promise<UserAccessDTO> {
    try {
      const uri = process.env.NODE_ENV !== 'production' ? 'http://localhost:3001/auth/login/' : 'https://api.cliffhangertv.com/auth/login/';

      const body = {
        username: email,
        password: password,
      };

      const data = await postURIAndReturnJSONAxiosNoAuth(uri, body);

      TokenService.setUsername(email);
      TokenService.setJWTAccessToken(data.accessToken);
      TokenService.setRefreshToken(data.refreshToken);

      const response = await RESTAPIAccessV2.get('/auth/useraccess');

      const userAccess: UserAccessDTO = response.data;

      if (Array.isArray(userAccess) && userAccess.length >= 1) {
        setToken(email, userAccess[0]?.userId ?? '');
        setPermissions(userAccess[0].permissions ?? []);
      }

      return userAccess;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async function refreshToken() {
    try {
      const refreshToken = TokenService.getRefreshToken();
      if (StringUtil.isUndefinedOrBlank(refreshToken)) {
        throw new Error('No Refresh Token!');
      }

      const data = await refreshOAuthToken(refreshToken);

      TokenService.setJWTAccessToken(data.accessToken);
      TokenService.setRefreshToken(data.refreshToken);
    } catch (error) {
      throw new Error('login failed');
    }
  }

  const setPermissions = (permissions: string[]) => {
    TokenService.setPermissions(permissions);
  };

  const setDevelopmentCycle = (developmentCycleId: string) => {
    const auth: UserAuthDTO = TokenService.getUserAuth();
    auth.developmentCycleId = developmentCycleId;
    TokenService.setUserAuth(auth);
  };

  const setToken = (username: string, userId?: string) => {
    const auth: UserAuthDTO = new UserAuthDTO();
    auth.username = username;
    auth.userId = userId;

    TokenService.setUserAuth(auth);
  };

  const logOut = () => {
    TokenService.clearTokens();
    sessionStorage.clear();
  };

  return {
    registerUser,
    logOut,
    logIn,
    googleLogIn,
    setDevelopmentCycle,
    setPermissions,
    refreshToken,
  };
}
