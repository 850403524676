import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useToken } from '../auth/useToken';
import TokenService from './tokenService';

const cliffHangerTVSVCURI = process.env.NODE_ENV !== 'production' ? 'http://localhost:3001/graphql' : 'https://api.cliffhangertv.com/graphql';

const GRAPHQLAPIAccessV2 = axios.create({
  baseURL: cliffHangerTVSVCURI,
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const graphqlMutateData = async (query: string, variables?: any) => {
  try {
    return await GRAPHQLAPIAccessV2.post(
      '',
      {
        query,
        variables,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  } catch (error) {
    console.log(error);
    alert('Error fetching data');
    throw new Error(error as any);
  }
};

const graphqlQueryData = async (query: string) => {
  try {
    const options: AxiosRequestConfig = {
      data: {
        query,
      },
    };

    return GRAPHQLAPIAccessV2.request(options);
  } catch (error) {
    console.log(error);
    alert('Error fetching data');
  }
};

GRAPHQLAPIAccessV2.interceptors.request.use(
  (config) => {
    const token = TokenService.getJWTAccessToken();

    if (token && config && config.headers) {
      config.headers['Authorization'] = token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

GRAPHQLAPIAccessV2.interceptors.response.use(
  (res: AxiosResponse) => {
    if (res && res.data.errors && Array.isArray(res.data.errors) && res.data.errors.length > 0) {
      if (res.data.errors[0]?.extensions?.code === 'UNAUTHENTICATED') {
        console.error('unauthenticated - refreshing token attempt');

        const { refreshToken } = useToken();

        refreshToken()
          .then(() => {
            console.error('token refreshed, but retry request for expired token not implemeneted');
          })
          .catch((err) => {
            console.error('token refreshed, but retry request for expired token not implemeneted');
            throw new Error(err);
          });
      }
    } else {
      return res.data;
    }

    //return res;
  },
  async (err) => {
    // TODO: renew token
    /*
    const originalConfig = err.config;
    if (originalConfig && originalConfig.url !== '/v1/login' && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const dto: RenewTokenDTO = new RenewTokenDTO();
          dto.username = TokenService.getUsername();
          dto.refreshToken = TokenService.getRefreshToken();

          const rs: { data: LoginResponseDTO } = await GRAPHQLAPIAccessV2.post('/v1/renewToken', dto);

          TokenService.setJWTAccessToken(rs.data.idToken);
          TokenService.setRefreshToken(rs.data.refreshToken);

          return GRAPHQLAPIAccessV2(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    */
    return Promise.reject(err);
  },
);

export { graphqlMutateData, graphqlQueryData };
