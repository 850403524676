import { DEFAULT_PATHS } from './config';
import TokenService from './dataAccess/tokenService';
import { RouteAndMenuItemModel } from './models/route/routeAndMenuItem.model';

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const userAuth = TokenService.getUserAuth();

const routesAndMenuItems: RouteAndMenuItemModel = {
  defaultRoutes: [
    {
      routePath: DEFAULT_PATHS.NOTFOUND,
      lazy: async () => {
        const { Component } = await import('./views/default/NotFound');
        return { Component };
      },
    },
    {
      routePath: DEFAULT_PATHS.LOGIN,
      lazy: async () => {
        const { Component } = await import('./views/default/Login');
        return { Component };
      },
    },
    {
      routePath: DEFAULT_PATHS.REGISTER,
      lazy: async () => {
        const { Component } = await import('./views/default/Register');
        return { Component };
      },
    },
    {
      routePath: DEFAULT_PATHS.UNAUTHORIZED,
      lazy: async () => {
        const { Component } = await import('./views/default/Unauthorized');
        return { Component };
      },
    },
  ],
  mainMenuItems: [
    {
      path: `/`,
      routePath: '/',
      label: 'menu.home',
      icon: 'grid-1',
      exact: true,
      lazy: async () => {
        const { Component } = await import('./views/home/Home');
        return { Component };
      },
    },
    {
      path: `/tvshows`,
      routePath: '/tvshows',
      label: 'menu.tv',
      icon: 'tv',
      exact: true,
      lazy: async () => {
        const { Component } = await import('./views/home/Home');
        return { Component };
      },
    },
    {
      routePath: '/tvshows/:showId/details',
      lazy: async () => {
        const { Component } = await import('./views/tvShows/TVShowDetail');
        return { Component };
      },
    },
    /*
    {
      path: `/movies`,
      routePath: '/movies',
      label: 'menu.movie',
      icon: 'video',
      exact: true,
      lazy: async () => {
        const { Component } = await import('./views/movies/Home');
        return { Component };
      },
    },
    */
    {
      routePath: '/movies/:movieId/details',
      lazy: async () => {
        const { Component } = await import('./views/movies/MovieDetail');
        return { Component };
      },
    },
  ],
  sidebarItems: [
    /*
    {
      path: `/companies/${userAuth.companyId}`,
      routePath: 'company/:companyId',
      label: 'menu.',
      icon: 'grid-1',
      exact: true,
      permission: '',
      // redirect: true,
      // to: `${appRoot}/company`,
      subs: [
        {
          path: `/company/${userAuth.companyId}/`,
          routePath: 'asdf',
          label: 'menu.',
          icon: 'grid-1',
          permission: 'CON',
          lazy: async () => {
            const { Component } = await import('./views/');
            return { Component };
          },
        },
        */
  ],
};

export default routesAndMenuItems;
