import axios from 'axios';
import { LoginResponseDTO } from '../models/auth/loginResponse.dto';
import { RenewTokenDTO } from '../models/auth/renewToken.dto';
import TokenService from './tokenService';
import { StringUtil } from '../utils/string.util';

export const cliffHangerTVSVCURI = process.env.NODE_ENV !== 'production' ? 'http://localhost:3001' : 'https://api.cliffhangertv.com';

const RESTAPIAccessV2 = axios.create({
  baseURL: cliffHangerTVSVCURI,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

RESTAPIAccessV2.interceptors.request.use(
  (config) => {
    const token = TokenService.getJWTAccessToken();

    if (token && config && config.headers) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

RESTAPIAccessV2.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig && originalConfig.url !== '/v1/login' && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const dto: RenewTokenDTO = new RenewTokenDTO();
          dto.username = TokenService.getUsername();
          dto.refreshToken = TokenService.getRefreshToken();

          const rs: { data: LoginResponseDTO } = await RESTAPIAccessV2.post('/v1/renewToken', dto);

          TokenService.setJWTAccessToken(rs.data.idToken);
          TokenService.setRefreshToken(rs.data.refreshToken);

          return RESTAPIAccessV2(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  },
);

export { RESTAPIAccessV2 };

export async function postURIAndReturnJSONAxiosNoAuth(uri: string, body: any) {
  try {
    const httpResponse = await axios.post(uri, body, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return httpResponse.data;
  } catch (error: any) {
    if (error.response) {
      console.log(error);
      console.log('bad status - ' + error.response.status, uri, error.response);
    }

    if (StringUtil.notUndefinedOrBlank(error?.response?.data?.message)) {
      throw new Error(error?.response?.data?.message);
    } else {
      throw new Error('No response');
    }
  }
}

export async function refreshOAuthToken(refreshToken: string) {
  const uri = 'http://localhost:3001/auth/refresh/';

  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: refreshToken,
    };

    const httpResponse = await axios.get(uri, {
      headers,
    });

    return httpResponse.data;
  } catch (error: any) {
    if (error.response) {
      console.log(error);
      console.log('bad status - ' + error.response.status, uri, error.response);
      throw new Error(error.response.data);
    } else {
      throw new Error('No response');
    }
  }
}
