import { createSlice } from '@reduxjs/toolkit';
import TokenService from '../dataAccess/tokenService';
import { UserModel } from '../models/auth/user.model';

const initialState = {
  isLogin: false as boolean, // false as boolean,
  currentUser: TokenService.getCurrentUser() as UserModel,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
      state.isLogin = false; // true;
    },
  },
});

export const { setCurrentUser } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
