// cra imports
import { createRoot, Root } from 'react-dom/client';
// import redux requirements
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { REACT_HELMET_PROPS } from './config';
import { HelperRoutingV6 } from './routingV6/helper.routingv6';
import { persistedStore, store } from './store';

const Main = () => {
  const router = useMemo(() => createBrowserRouter(HelperRoutingV6.getRoutes()), []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
        <Helmet {...REACT_HELMET_PROPS} />
        <ToastContainer transition={Slide} newestOnTop />
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  );
};

const container: HTMLElement | null = document.getElementById('root');
if (container) {
  const root: Root = createRoot(container);
  root.render(<Main />);
} else {
  console.log('no root element');
}

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
// reportWebVitals();
