import { createSlice } from '@reduxjs/toolkit';
import { NotificationDA } from '../../../dataAccess/notification/notification.da';
const initialState = {
  status: 'idle',
  items: [],
};
const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notificationsLoading(state: any) {
      state.status = 'loading';
    },
    notificationsLoaded(state, action) {
      state.items = action.payload;
      state.status = 'idle';
    },
  },
});
export const { notificationsLoading, notificationsLoaded } = notificationSlice.actions;
export const fetchNotifications = () => async (dispatch: any) => {
  try {
    dispatch(notificationsLoading());

    // TODO: Company Id
    const data = await NotificationDA.getNotifications(['acknowledged', 'title', 'message', 'notificationTypeENUM', 'recordId'], false);

    dispatch(notificationsLoaded(data));
  } catch (error) {
    console.log(error);
  }
};
const notificationReducer = notificationSlice.reducer;
export default notificationReducer;
