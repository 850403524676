import { StringUtil } from '../../utils/string.util';
import { graphqlMutateData, graphqlQueryData } from '../graphqlAPIAccessV2.da';

export class NotificationDA {
  static async getNotifications(fields: string[], acknowledged?: boolean): Promise<any> {
    try {
      const data = await graphqlQueryData(`query getNotifications{
        getNotifications(
            ${StringUtil.notUndefinedOrBlank(acknowledged) ? `acknowledged: ${acknowledged}` : ''}            
        ) {
            notificationId
            ${fields.join(',')}
        }
    }`);

      return data?.data?.getNotifications ?? [];
    } catch (error: any) {
      throw new Error(error);
    }
  }

  static async acknowledgeNotification( notificationId: string): Promise<boolean> {
    try {
      const query = `mutation AcknowledgeNotification {
      acknowledgeNotification(
        notificationId: "${notificationId}"
        )
      }`;

      const data = await graphqlMutateData(query);

      return data?.data?.acknowledgeNotification ?? false;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  }
}
